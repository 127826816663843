<template>
  <v-container
    fluid
    class="fill-height login_desktop pb-5"
    style="align-items: center"
  >
    <v-row
      :class="
        $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly ? 'px-16' : ''
      "
      align="center"
      justify="center"
    >
  
        <v-card
          class="pa-3"
          flat
          style="position: relative; border-radius: 25px"
        >
          <v-row align="center" justify="center">
            <v-col cols="12" align="center">
              <v-img
                :src="require('@/assets/img/icon_fujifilm.png')"
                max-height="120px"
                max-width="250px"
                contain
              ></v-img>
            </v-col>

            <v-col class="mt-10" cols="12" align="center">
              <div
                class="black--text py-2"
                style="
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 450;
                  line-height: 25px;
                  letter-spacing: 0em;
                "
              >
                ขออภัยค่ะ สัญญานี้ได้รับการยืนยันแล้ว
                กรณีต้องการแก้ไขกรุณาติดต่อฝ่ายขาย
              </div>
              <div
                class="black--text pt-0 pb-2"
                style="
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 450;
                  line-height: 25px;
                  letter-spacing: 0em;
                "
              >
                We're sorry, your contract has already been confirmed. Should
                you need further assistance,
              </div>
              <div
                class="black--text pt-0 pb-2"
                style="
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 450;
                  line-height: 25px;
                  letter-spacing: 0em;
                "
              >
                please contact our sales department.
              </div>
            </v-col>

            <v-col class="mt-10" cols="12" align="center">
              <v-btn
                color="primary"
                class="px-10 white--text text-capitalize"
                @click="goToLogin()"
                style="
                  border-radius: 10px;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 450;
                  line-height: 25px;
                  letter-spacing: 0em;
                  text-align: left;
                "
              >
                Back
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
  
    </v-row>
  </v-container>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},

  created() {},
  mounted() {},
  methods: {
    goToLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped></style>
